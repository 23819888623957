import { ScrapedArticle, ScrapeTask } from "@/types";

import api from '@/plugins/axios';

export const UpdateScrapedArticle = async (articleId: string, props: Partial<ScrapedArticle>): Promise<ScrapedArticle> => {
  const { data } = await api.patch<ScrapedArticle>(`/scraper/articles/${articleId}`, {
    ...props
  })

  return data;
}

export const GetScrapedArticles = async (limit: number, offset: number): Promise<{ rows: ScrapedArticle[], count: number }> => {
  const { data } = await api.get<{ rows: ScrapedArticle[], count: number }>('/scraper', {
    params: {
      limit,
      offset
    }
  })

  return data;
}


export const GetScrapeTasks = async (limit?: number): Promise<ScrapeTask[]> => {
  const { data } = await api.get<ScrapeTask[]>(`/scraper/tasks`, {
    params: {
      limit
    }
  })

  return data;
}

export const ExecuteScraper = async (): Promise<void> => {
  await api.post<ScrapeTask>(`/scraper/execute`)

  return;
}

export const GetScrapeTask = async (scrapeTaskId: string, includeArticles = false): Promise<ScrapeTask> => {

  if (includeArticles) {
    const { data } = await api.post<ScrapeTask>(`/tasks/${scrapeTaskId}?includeArticles=true`)

    return data;
  }

  const { data } = await api.post<ScrapeTask>(`/tasks/${scrapeTaskId}?includeArticles=false`)

  return data;
}

export const GetScrapedArticle = async (articleId: string): Promise<ScrapedArticle> => {
  const { data } = await api.get<ScrapedArticle>(`/scraper/articles/${articleId}`);
  return data;
}