

import { ClaimReview, Factcheck, Partner, ScrapedArticle } from "@/types";
import { Component, Vue, Watch } from "vue-property-decorator";

import { GetFactCheck, GetPartners, CreateClaimReview } from "@/services/FactCheckService";
import ClaimReviewForm from "@/components/forms/ClaimReviewForm.vue";
import { format } from "date-fns";
import { GetScrapedArticle } from "@/services/ScraperService";
import { ROOT_ACTIONS } from "@/store/actions";
import * as StorageServices from "@/services/StorageServices";

@Component({
    components: {
        ClaimReviewForm
    }
})
export default class AddClaimReview extends Vue {
    article = null as Factcheck | null;
    partners = [] as Partner[];
    scrapedArticle = null as null | ScrapedArticle;

    prefilledFields: Array<{ from: string, field: string }> = [];

    claimReview: Partial<ClaimReview> = {
        languageCode: '',
        numericRating: 1,
        conclusion: '',
        textualRating: '',
        url: '',
        date: new Date().toISOString().substring(0, 10),
        title: '',
        PartnerId: '',
        hasVideo: false,
        isPriority: false,
    }

    imageSelectOptions = [
        { text: "Uploaden", value: "UPLOAD" },
        { text: "Afbeelding linken", value: "LINK" },
    ];

    imageSelect = "UPLOAD";

    photoOverrrideWarning = false;
    imageError = false;

    imageUploadUrl = "";
    customImageSrc = "";

    loading = false;

    async mounted() {
        await this.fetchFactcheck();
        this.partners = await GetPartners();
        if (this.$route.query.article) this.fetchAndPrefillScrapedArticle();
    }

    async submit() {
        try {
            this.loading = true;
            if (this.article) {
                if (this.article.ClaimId) {
                    this.loading = true;
                    let imageUrl = 'https://cdn.decheckers.be/images/empty';

                    if (this.imageSelect === 'UPLOAD') {
                        const tempImageId = (Math.random() + 1).toString(36).substring(7);

                        let htmlElement = document.getElementById("photo") as HTMLInputElement;

                        if (!htmlElement) {
                            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                                color: 'error',
                                text: 'error',
                            })
                            return;
                        }

                        if (!htmlElement.files) {
                            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                                color: 'error',
                                text: 'error',
                            })
                            return;
                        }

                        const file = htmlElement.files[0];
                        await StorageServices.UploadPhoto(`images/${tempImageId}`, file);
                        imageUrl = await StorageServices.GetDownloadUrl(`images/${tempImageId}`);
                    } else if (this.imageSelect === 'UPLOAD') {
                        const tempImageId = (Math.random() + 1).toString(36).substring(7);
                        let htmlElement = document.getElementById("photo") as HTMLInputElement;
                        const file = htmlElement.files![0];
                        await StorageServices.UploadPhoto(`images/${tempImageId}`, file);
                        imageUrl = await StorageServices.GetDownloadUrl(`images/${tempImageId}`);
                    } else if (this.imageSelect === 'SCRAPED') {
                        imageUrl = await StorageServices.GetDownloadUrl(
                            `images/${this.scrapedArticle!.id}`
                        );
                    } else if (this.imageSelect === 'LINK') {
                        imageUrl = this.customImageSrc;
                    } else if (this.imageSelect.startsWith('OTHER_CLAIMREVIEW/')) {
                        imageUrl = `https://cdn.decheckers.be/images/${this.imageSelect.split('/')[1]}`;
                    }

                    await CreateClaimReview(this.article.ClaimId, this.claimReview, this.scrapedArticle ? this.scrapedArticle.id : null, imageUrl);
                }
            }
        } catch (error) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'error',
                text: 'error',
            })
        } finally {
            this.loading = false;
            this.$router.push(`/factchecks/${this.article!.id}/overview`)
        }
    }

    async fetchFactcheck() {
        this.article = await GetFactCheck(this.$route.params.id);
        if (this.article === null) {
            this.$router.push("/error?code=404");
        }

        for (let claimreview of this.article!.Claim!.ClaimReviews) {
            this.imageSelectOptions.push({
                text: 'Factcheck van ' + claimreview.Partner!.name,
                value: 'OTHER_CLAIMREVIEW/' + claimreview.id!.toString(),
            })
        }
    }

    async fetchAndPrefillScrapedArticle() {
        const article = await GetScrapedArticle(this.$route.query.article.toString());
        if (article) {
            this.scrapedArticle = article;
            this.imageSelectOptions.unshift({
                text: "Gescrapte afbeelding",
                value: "SCRAPED",
            });
            this.imageSelect = "SCRAPED";

            this.claimReview.PartnerId = this.scrapedArticle.PartnerId;

            this.claimReview.title = this.scrapedArticle.title;
            this.claimReview.url = this.scrapedArticle.url;
            this.claimReview.date = this.scrapedArticle.date ? new Date(this.scrapedArticle.date).toISOString().substring(0, 10) : new Date(this.scrapedArticle.createdAt).toISOString().substring(0, 10)

            this.claimReview.publisher = this.scrapedArticle.factCheckAuthor;
            this.claimReview.url = this.scrapedArticle.url;

            if (this.scrapedArticle.claimReviewData) {
                const cr = this.scrapedArticle.claimReviewData

                if (cr.reviewRating.alternateName) {
                    if (cr.author.name === 'dpa') {
                        this.claimReview.conclusion = cr.reviewRating.alternateName
                    }
                    this.claimReview.textualRating = cr.reviewRating.alternateName
                    this.prefilledFields.push({ from: 'claimReview', field: 'textualRating' })
                }

                if (cr.reviewRating.ratingValue) {
                    this.claimReview.numericRating = parseInt(cr.reviewRating.ratingValue, 10);
                    this.prefilledFields.push({ from: 'claimReview', field: 'numericRating' })
                }

                if (cr.datePublished) {
                    this.claimReview.date = new Date(cr.datePublished).toISOString().substring(0, 10)
                    this.prefilledFields.push({ from: 'claimReview', field: 'claimreview-date' })
                }

            }
        }
    }

    @Watch('imageSelect')
    onImageSelectChange() {
        console.log(this.imageSelect);
        if (this.imageSelect.startsWith('OTHER_CLAIMREVIEW/')) {
            this.customImageSrc = `https://cdn.decheckers.be/images/${this.imageSelect.split('/')[1]}`;
        }
    }

    formatDate(d: string): string {
        return format(new Date(d), "dd-MM-yyyy");
    }

    showImagePreview() {
        this.customImageSrc = URL.createObjectURL(
            //@ts-ignore
            document.getElementById("photo").files[0]
        );
        this.$forceUpdate();
    }
}

